import { FC } from 'react';
import IconProps from './IconProps';

const DataTransferControlsIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  const attr = { onClick: onClick, className: `inline ${cursor} ${className}` };

  if (gradient) {
    return (
      <svg {...attr} viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.4722 14.4236C11.2758 14.5414 11.0402 14.4629 10.9223 14.3058C10.8438 14.1094 10.8831 13.8738 11.0794 13.7559C11.3544 13.5988 11.6685 13.481 11.9827 13.4025C11.4329 12.0279 10.0976 11.0853 8.60516 11.0853C6.75927 11.0853 5.22758 12.4599 4.99193 14.2665C5.03121 14.5414 4.83483 14.7378 4.55992 14.6985C3.10677 14.5021 1.77145 15.5233 1.77145 17.055C1.77145 18.351 2.83185 19.4114 4.16717 19.4114H12.7682C14.2214 19.4114 15.3996 18.2332 15.3996 16.78C15.3996 15.2876 14.2214 14.1094 12.7682 14.1094C12.2969 14.1094 11.8649 14.2272 11.4722 14.4236ZM12.7682 13.3239C14.6534 13.3239 16.1851 14.8556 16.1851 16.78C16.1851 18.6652 14.6534 20.1969 12.7682 20.1969H4.16717C2.39983 20.1969 0.985962 18.783 0.985962 17.055C0.985962 15.2091 2.43911 13.8738 4.285 13.8738C4.63846 11.7922 6.48435 10.2998 8.60516 10.2998C10.4903 10.2998 12.1791 11.5173 12.7682 13.3239Z"
          fill="url(#paint0_linear_4183_66126)"
          stroke="url(#paint1_linear_4183_66126)"
          strokeWidth="0.711305"
        />
        <path
          d="M12.5452 25.8651C8.99062 27.8116 4.5533 26.8199 2.15016 23.6161C1.86713 23.2388 2.00447 22.7107 2.41113 22.4717C2.81334 22.2354 3.32678 22.3725 3.61644 22.7382C5.5072 25.1253 8.86881 25.8572 11.6089 24.4457L10.3254 24.1588C9.87244 24.0682 9.57043 23.6151 9.66103 23.1621C9.75163 22.7091 10.2046 22.4071 10.6576 22.4977L13.9193 23.1621C14.1005 23.1923 14.2364 23.2678 14.3572 23.3886C14.5082 23.5396 14.6139 23.736 14.6139 23.9474L14.6139 23.9776L14.6139 24.0682C14.6139 24.0984 14.5988 24.1437 14.5988 24.1739L13.9495 27.4204C13.8589 27.8734 13.4059 28.1754 12.9529 28.0848C12.4999 27.9942 12.1979 27.5412 12.2885 27.0882L12.5452 25.8651Z"
          fill="url(#paint2_linear_4183_66126)"
        />
        <path
          d="M3.44998 6.17512C6.37959 3.37494 10.9224 3.1844 14.0729 5.65705C14.4439 5.94827 14.4479 6.49387 14.117 6.82995C13.7896 7.16235 13.2582 7.16281 12.8838 6.88454C10.4396 5.06808 7.00308 5.23117 4.72166 7.30386L6.03569 7.24879C6.4967 7.21906 6.90566 7.57846 6.9354 8.03948C6.96513 8.50049 6.60572 8.90945 6.14471 8.93918L2.82226 9.14159C2.63941 9.15932 2.4886 9.12156 2.34065 9.03614C2.15571 8.92937 2.00281 8.76711 1.94809 8.56291L1.94028 8.53374L1.91683 8.44623C1.90901 8.41706 1.91187 8.36939 1.90406 8.34022L1.69097 5.03627C1.66124 4.57525 2.02064 4.16629 2.48166 4.13656C2.94268 4.10683 3.35164 4.46623 3.38137 4.92725L3.44998 6.17512Z"
          fill="url(#paint3_linear_4183_66126)"
        />
        <defs>
          <linearGradient id="paint0_linear_4183_66126" x1="0.985962" y1="13.3218" x2="13.5484" y2="21.5689" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint1_linear_4183_66126" x1="0.985962" y1="13.3218" x2="13.5484" y2="21.5689" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint2_linear_4183_66126" x1="11.9091" y1="29.6583" x2="8.73831" y2="17.7766" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint3_linear_4183_66126" x1="3.08259" y1="2.34657" x2="9.22056" y2="13.0027" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
  return (
    <svg {...attr} className={`inline ${cursor} ${className}`} viewBox="0 0 18 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1571 14.5382C11.9607 14.6561 11.7251 14.5775 11.6073 14.4204C11.5287 14.224 11.568 13.9884 11.7644 13.8706C12.0393 13.7135 12.3535 13.5957 12.6677 13.5171C12.1178 12.1425 10.7825 11.1999 9.2901 11.1999C7.44421 11.1999 5.91251 12.5745 5.67687 14.3811C5.71614 14.6561 5.51977 14.8524 5.24485 14.8132C3.79171 14.6168 2.45638 15.6379 2.45638 17.1696C2.45638 18.4657 3.51679 19.5261 4.85211 19.5261H13.4532C14.9063 19.5261 16.0845 18.3478 16.0845 16.8947C16.0845 15.4023 14.9063 14.224 13.4532 14.224C12.9819 14.224 12.5499 14.3419 12.1571 14.5382ZM13.4532 13.4386C15.3383 13.4386 16.87 14.9703 16.87 16.8947C16.87 18.7799 15.3383 20.3115 13.4532 20.3115H4.85211C3.08477 20.3115 1.6709 18.8977 1.6709 17.1696C1.6709 15.3237 3.12404 13.9884 4.96993 13.9884C5.3234 11.9069 7.16929 10.4144 9.2901 10.4144C11.1753 10.4144 12.864 11.6319 13.4532 13.4386Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.711305"
      />
      <path
        d="M13.2297 25.9799C9.67519 27.9264 5.23787 26.9347 2.83473 23.7309C2.5517 23.3536 2.68904 22.8255 3.0957 22.5865C3.49791 22.3502 4.01135 22.4873 4.30101 22.853C6.19177 25.2402 9.55339 25.972 12.2935 24.5605L11.01 24.2736C10.557 24.183 10.255 23.73 10.3456 23.277C10.4362 22.8239 10.8892 22.5219 11.3422 22.6125L14.6038 23.277C14.785 23.3072 14.9209 23.3827 15.0417 23.5035C15.1927 23.6545 15.2984 23.8508 15.2984 24.0622L15.2984 24.0924L15.2984 24.183C15.2984 24.2132 15.2833 24.2585 15.2833 24.2887L14.634 27.5352C14.5434 27.9882 14.0904 28.2902 13.6374 28.1996C13.1844 28.109 12.8824 27.656 12.973 27.203L13.2297 25.9799Z"
        fill={accentColor}
      />
      <path
        d="M4.13467 6.28985C7.06429 3.48967 11.6071 3.29913 14.7575 5.77178C15.1286 6.063 15.1326 6.6086 14.8016 6.94468C14.4743 7.27708 13.9429 7.27754 13.5685 6.99927C11.1243 5.18281 7.68777 5.3459 5.40635 7.41859L6.72038 7.36352C7.1814 7.33379 7.59036 7.69319 7.62009 8.15421C7.64982 8.61522 7.29042 9.02418 6.8294 9.05391L3.50695 9.25632C3.3241 9.27405 3.17329 9.23629 3.02534 9.15087C2.84041 9.0441 2.6875 8.88184 2.63279 8.67765L2.62497 8.64847L2.60152 8.56096C2.5937 8.53179 2.59656 8.48412 2.58875 8.45495L2.37567 5.151C2.34593 4.68998 2.70534 4.28102 3.16635 4.25129C3.62737 4.22156 4.03633 4.58096 4.06606 5.04198L4.13467 6.28985Z"
        fill={accentColor}
      />
    </svg>
  );
};

export default DataTransferControlsIcon;
